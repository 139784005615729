// @flow
import { makeStyles } from '@material-ui/core';
import Footer from '../../assets/images/Footer';
import React from 'react';

const useStyle = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  footerImage: {
    height: 15,
    width: 145,
  },
});

export default function CommonFooter() {
  const classes = useStyle();

  return (
    <div
      className={classes.container}
      onClick={() => {
        window.open('https://www.connexustravel.com/', '_blank');
      }}
    >
      <Footer className={classes.footerImage} />
    </div>
  );
}
