const kanooTravelConfig = {
  firebaseConfig: {
    apiKey: "AIzaSyC-KFYdDlozVRvAed17rx286f-gkvdqonw",
    authDomain: "kanoo-travel.firebaseapp.com",
    projectId: "kanoo-travel",
    storageBucket: "kanoo-travel.appspot.com",
    messagingSenderId: "1084248458247",
    appId: "1:1084248458247:web:79cd38f9e6abd382dfd6a2",
  },
};

export default kanooTravelConfig;
