import { formatDate } from '../../../../libs/date';
import { makeStyles } from '@material-ui/core';
import { reload } from '../../../../libs/libs';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/CommonButton';
import QRCode from 'react-qr-code';
import React, { useState } from 'react';
import SuccessfulDialog from '../SuccessfulDialog';
import colors from '../../../../style/colors';
import memberApi from '../../../../libs/api/memberApi';
import useStore from '../../../../hooks/useStore';

const useStyles = makeStyles({
  container: {
    minHeight: 320,
    backgroundColor: colors.secondary,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 32,
    marginBottom: 32,
  },
  titleContainer: {
    marginBottom: 16,
  },
  qrCodeContainer: {
    padding: 16,
    backgroundColor: colors.white,
    height: 200,
    width: 200,
  },
  itemTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: colors.branding,
  },
  itemSubtitle: {
    fontSize: 16,
    fontWeight: 500,
    color: colors.branding,
  },
  itemInfo: {
    fontSize: 14,
    fontWeight: 500,
    color: colors.text.light,
    marginTop: 16,
  },
  rewardTitle: {
    fontSize: 24,
    fontWeight: 700,
    color: colors.branding,
  },
  rewardInfo: {
    fontSize: 13,
    fontWeight: 500,
    color: colors.text.secondary,
    letterSpacing: '0.157px',
  },
  rewardInfoContainer: {
    marginBottom: 24,
  },
});

export default function InfoCard(props) {
  const classes = useStyles();
  const { data, type } = props;
  const { lang, dispatch } = useStore();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [redeemedItem, setRedeemedItem] = useState();
  const [redeemSuccessfulModalOpen, setRedeemSuccessfulModalOpen] = useState(
    false,
  );

  const isRedeemable = data?.issues?.length === 0;

  const redeemItem = async () => {
    try {
      setIsLoading(true);
      const response = await memberApi.redeemItem({
        id: data?.id,
        costId: data?.cost?.id,
      });
      if (response[0]?.type) {
        setRedeemedItem(response[0]);
        setRedeemSuccessfulModalOpen(true);
        reload({ dispatch: dispatch });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: 'SET_DIALOG',
        payload: {
          open: true,
          message: error?.response?.data?.error?.message,
        },
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className={classes.container}>
        {(type === 'item' || redeemedItem?.type === 'item') && (
          <>
            <div className={classes.titleContainer}>
              <span className={classes.itemTitle}>{t('reward.qrTitle')}</span>
            </div>
            {(data?.token || redeemedItem?.token) && (
              <div className={classes.qrCodeContainer}>
                <QRCode
                  size={168}
                  value={redeemedItem ? redeemedItem?.token : data?.token}
                  viewBox={`0 0 168 168`}
                />
              </div>
            )}
            <span className={classes.itemInfo}>7H3GF9WYUS</span>
            {data?.expire_at && (
              <span className={classes.itemInfo}>
                {t('reward.expiryDate', {
                  date: formatDate(data?.expire_at, lang),
                })}
              </span>
            )}
          </>
        )}
        {type === 'redeemable' && !redeemedItem && (
          <>
            <div className={classes.titleContainer}>
              <span className={classes.rewardTitle}>
                {t('reward.redeemFor1', {
                  point: data?.cost?.point?.amount ?? '',
                  pointScheme: data?.cost?.point?.title[lang] ?? '',
                })}
              </span>
              <br />
              <span className={classes.rewardTitle}>
                {t('reward.redeemFor2', {
                  point: data?.cost?.point?.amount ?? '',
                  pointScheme: data?.cost?.point?.title[lang] ?? '',
                })}
              </span>
            </div>
            <div className={classes.rewardInfoContainer}>
              <span className={classes.rewardInfo}>
                {t('reward.reminder', {
                  pointScheme: data?.cost?.point?.title[lang] ?? '',
                })}
              </span>
            </div>
            <CommonButton
              onClick={redeemItem}
              text={
                isRedeemable
                  ? t('reward.confirmRedeem')
                  : data?.issues?.length > 0
                  ? `${data?.issues[0][lang]}`
                  : ''
              }
              active={isRedeemable}
              loading={isLoading}
            />
          </>
        )}
      </div>
      <SuccessfulDialog
        open={redeemSuccessfulModalOpen}
        onActionButtonClick={() => {
          setRedeemSuccessfulModalOpen(false);
        }}
        onClose={() => {
          setRedeemSuccessfulModalOpen(false);
        }}
      />
    </>
  );
}
