import { Divider, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CommonActiveButton from '../../components/CommonActiveButton';
import CommonAppBar from '../../components/CommonAppBar';
import CommonPageTitle from '../../components/CommonPageTitle';
import MemberCard from './components/MemberCard';
import MissionList from './components/MissionList';
import React from 'react';
import RewardList from './components/RewardList';
import WalletList from './components/WalletList';
import useStore from '../../hooks/useStore';

const useStyles = makeStyles({
  container: {
    maxWidth: 768,
    margin: '0px auto',
    paddingLeft: 24,
    paddingRight: 24,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '24px 0px',
  },
  button: {
    margin: '0px 8px',
  },
  divider: {
    marginTop: 16,
    marginBottom: 32,
  },
});

export default function Home() {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    dispatch,
    init,
    me,
    missions,
    redeemables,
    items,
    tierStyle,
    showMissionOrReward,
  } = useStore();

  return (
    <>
      <CommonAppBar />
      <div className={classes.container}>
        <CommonPageTitle title={t('home.title')} />
        <MemberCard data={me} isLoading={!init} tierStyle={tierStyle} />
        <div className={classes.row}>
          <CommonActiveButton
            active={showMissionOrReward === 'mission'}
            text={t('home.missions')}
            onClick={() => {
              dispatch({
                type: 'SET_SHOW_MISSION_OR_REWARD',
                payload: 'mission',
              });
            }}
            margin="0px 8px"
          />
          <CommonActiveButton
            active={showMissionOrReward === 'reward'}
            text={t('home.rewards')}
            onClick={() => {
              dispatch({
                type: 'SET_SHOW_MISSION_OR_REWARD',
                payload: 'reward',
              });
            }}
            margin="0px 8px"
          />
        </div>
        {showMissionOrReward === 'mission' && (
          <MissionList data={missions} isLoading={!init} />
        )}
        {showMissionOrReward === 'reward' && (
          <>
            {items?.length > 0 && (
              <>
                <WalletList data={items} />
                <Divider className={classes.divider} />{' '}
              </>
            )}
            {redeemables?.length > 0 && <RewardList data={redeemables} />}
          </>
        )}
      </div>
    </>
  );
}
