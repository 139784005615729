import { Card, makeStyles } from '@material-ui/core';
import { Grid } from '@mui/material';
import { formatDate } from '../../../../libs/date';
import { useTranslation } from 'react-i18next';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import React from 'react';
import StampIcon from '../../../../assets/icons/StampIcon';
import colors from '../../../../style/colors';
import styled from '@emotion/styled';
import useStore from '../../../../hooks/useStore';

const useStyles = makeStyles({
  card: {
    border: '1px solid',
    borderColor: colors.grey10,
    boxShadow: 'none',
    padding: 12,
    marginBottom: 24,
    textAlign: 'left',
  },
  image: {
    minWidth: 88,
    boxShadow: 'none',
    borderRadius: 5,
  },
  imageContent: {
    objectFit: 'cover',
    width: 88,
    height: 88,
  },
  detailContainer: {
    marginLeft: 16,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.branding,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.branding,
  },
  info: {
    fontSize: 14,
    fontWeight: 500,
    color: colors.branding,
  },
  stampIcon: {
    fontSize: 14,
    color: colors.branding,
    marginRight: 5,
  },
  infoHighlighted: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.branding,
  },
  buttonText: {
    fontSize: 10,
    color: colors.branding,
    fontWeight: 600,
    textDecoration: 'underline',
  },
  divider: {
    margin: '16px 0px',
    backgroundColor: colors.grey10,
  },
  stamp: {
    height: 45,
    width: 45,
  },
  gridItem: {
    textAlign: 'center',
  },
  grid: {
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 24,
    marginBottom: 16,
  },
});

export default function StampCard(props) {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { lang } = useStore();

  const totalStamp = data?.progress?.target;
  const currentStamp = data?.progress?.current;

  const getStampRowNumber = () => {
    let i = 1;
    while (totalStamp / i > 5) {
      i++;
    }
    return i;
  };

  const getStampColumnNumber = () => {
    const row = getStampRowNumber();
    return Math.ceil(totalStamp / row);
  };

  const stampColumnNumber = getStampColumnNumber();

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 5,
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 16,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colors.progressBar,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colors.branding,
    },
  }));

  const Stamp = () => {
    return <img className={classes.stamp} alt="stamp" src={data?.stamp?.url} />;
  };

  const StampEmpty = () => {
    return (
      <img
        className={classes.stamp}
        alt="stamp_empty"
        src={data?.stamp_placeholder?.url}
      />
    );
  };

  return (
    <>
      <div className={classes.row}>
        <span className={classes.subtitle}>
          {data?.expire_at
            ? t('home.endsOn', { date: formatDate(data?.expire_at, lang) })
            : ' '}
        </span>
        <span>
          <StampIcon className={classes.stampIcon} />
          <span className={classes.infoHighlighted}>{currentStamp}</span>
          <span className={classes.info}>
            {' '}
            / {totalStamp} {t((data?.display_type === 'stamp') ? 'home.stamps' : 'home.progresses')}
          </span>
        </span>
      </div>
      <Card className={classes.card}>
        {data?.display_type !== 'progress' && (
          <Grid
            className={classes.grid}
            container
            columns={stampColumnNumber}
            rowSpacing={2}
          >
            {[...Array(currentStamp ?? 0)].map((_, index) => (
              <Grid className={classes.gridItem} item xs={1} key={index}>
                <Stamp className={classes.stamp} />
              </Grid>
            ))}
            {[...Array(totalStamp - (currentStamp ?? 0))].map((_, index) => (
              <Grid className={classes.gridItem} item xs={1} key={index}>
                <StampEmpty className={classes.stamp} />
              </Grid>
            ))}
          </Grid>
        )}
        {data?.display_type === 'progress' && (
          <BorderLinearProgress
            variant="determinate"
            value={(currentStamp / totalStamp) * 100}
          />
        )}
      </Card>
    </>
  );
}
